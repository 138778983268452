import { IframeFormHandleGetter } from '../public/iframe-form-handle';
import { proxyEventsToJQuery } from './proxy-form-events-to-jquery';

/**
 * The information that is captured when a 'prefill' method call was made using the legacy (embed.js) iframe embed API
 */
export type DeferredIFramePrefillAction = {
	method: 'prefill';
	arguments: [object, HTMLIFrameElement];
	targetFrame: HTMLIFrameElement;
	message: {
		event: 'prefill';
		data: {
			entry: object
		};
	};
};

/**
 * The information that is captured when a 'setCss' method call was made using the legacy (embed.js) iframe embed API
 */
export type DeferredIFrameSetCssAction = {
	method: 'setCss';
	arguments: [string, boolean];
	targetFrame: null;
	message: {
		event: 'setCss';
		data: {
			css: string
		};
	};
};

/**
 * The compatibility API that is exposed by the legacy iframe API (embed.js) for V2 forms compatibility
 */
export type LegacyIFrameEmbedCompatibilityApi = {
	activateV2Api(compat: IFrameEmbedCompatibilityApi): void;
};

/**
 * An API exposed by the Vue forms client for processing method calls that were made using the legacy (embed.js) iframe embed API
 */
export class IFrameEmbedCompatibilityApi {
	private readonly getApi: IframeFormHandleGetter;
	constructor(getApi: IframeFormHandleGetter) {
		this.getApi = getApi;
	}

	/**
	 * Handles deferred actions that occured prior to the iframe API loading
	 * @param action The action that was deferred until the iframe could accept messages
	 * @param iframe The iframe that is now ready to accept messages
	 */
	handleDeferredAction(action: DeferredIFramePrefillAction | DeferredIFrameSetCssAction, iframe: HTMLIFrameElement) {
		if (action.method === 'prefill') {
			// Proxy the prefill call from the legacy API (embed.js) to the new V2 forms iframe API
			const entry = action.arguments[0];
			const targetFrame = action.targetFrame;
			this.getApi(targetFrame).prefill(entry);
		}
		else if (action.method === 'setCss') {
			// Proxy the setCss call from the legacy API (embed.js) to the new V2 forms iframe API
			const css = action.arguments[0];
			const suppressWarning = action.arguments[1];
			if (!suppressWarning)
				console.warn('The form CSS has changed significantly with V2 forms, please consider making updates and using the new forms API.');
			this.getApi(iframe).setCss(css);
		}
	}

	/**
	 * Proxy events from the iframe as jQuery events on the hosting page
	 * @param iframe The iframe to listen for events
	 * @param jQuery The jQuery object to proxy events to
	 */
	proxyJQueryEvents(iframe: HTMLIFrameElement, jQuery: JQueryStatic): void {
		proxyEventsToJQuery(this.getApi(iframe), jQuery, false);
	}
}
